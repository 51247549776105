<template>
  <div>
    <div v-html="html"></div>
  </div>
</template>

<script>
import request from "../api/index";
export default {
  // props: ["id"],
  data() {
    return {
      cId: '',
      html: ''
    }
  },
  created() {
    this.cId = this.$route.query.cId;
    const success = (res) => {
      this.html = res;
    }
    request(
      `naskpackage/getartlist?cId=${this.cId}`,
      success
    );
  }
}
</script>

<style>

</style>
